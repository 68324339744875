import React from "react"

import colors from "../colors"

import { LandingLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import SingleColumnBlock from "../components/singleColumnBlock"
import PersonList from "../components/person/PersonList"
import Map from "../components/map"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const LandingMBAPAS = () => {
  return (
    <LandingLayout>
      <SEO
        title="The Long Term Care Insurance Consultation with Corey Rieck"
        ogDescription="We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <img
              src="/images/landing/mba-pas-logos.png"
              alt="Multiplied Benefits Architecture - Purchasing Alliance Solutions"
            />
          <p>
          Thank you for being a valued client of Purchasing Alliance and Multiplied Benefits Architecture for your Medicare-related solutions. We appreciate you and will continue to have your long term well-being in mind.
          </p>
          <p>
          We have heard from many of you who have questions on coverage from Medicare and what it covers as it pertains to long term care for you or for your loved ones. 
          </p>
          <p>
          We have arranged for you to set up a free, no-obligation LTC Consult with Corey Rieck. Corey will confidentially and personally evaluate your family’s specific needs and answer all your questions on long term care. This consult fee, normally valued at $99.95, is waived when you mention that you are an existing client of Purchasing Alliance/Multiplied Benefits Architecture.
          </p>

          <h2>Request a meeting or phone call</h2>
          <p>
          Click the button below to schedule your free, no-obligation LTC Consult with Corey Rieck of The Long Term Care Planning Group (a $99.95 value). Or, you can call <a href="tel:4043831377">(404) 383-1377</a> to schedule a meeting over the phone.
          </p>
          
          <p>
           <ScheduleVisitButton />
          </p>

          <h2>Or ... sign up for our free webinar</h2>
          <p>
          Sometimes the best way to learn more about a complex topic is to attend a short webinar to start with the basics. Even if you already have done long term care planning, you may still have unanswered questions about your coverage or plan. This webinar is about 20 minutes. Corey quickly introduces himself and then covers important details you need to know about long term care planning. You'll also receive a summary PDF overview for your review.
          </p>
          
          <div class="widget_wrapper"><div class="ewp_form_container ewp_widget_container" webinar_id="wz5yEgEd5A9rbTE3ZNAR9A==" ></div> <script type="text/javascript" src="https://ewpcdn.easywebinar.com/widget/js/ewp_widget.js?v=1.27.5" ></script></div>
          </div>
        }
      />
      <PageWrapper>
        <div
          style={{
            height: "1px",
            width: "100%",
            borderTop: "3px solid whitesmoke",
            display: "block",
            margin: "50px 0 50px 0",
          }}
        ></div>
        <PersonList shortnames={["corey-rieck"]} />
      </PageWrapper>
    </LandingLayout>
  )
}

export default LandingMBAPAS
